<template>
  <div class="task-card">
    <div v-if="error" class="error-msg text-center mt-2">
      <ul v-if="Array.isArray(error)">
        <li v-for="(e, index) in error" :key="index">
          {{ e }}
        </li>
      </ul>
      <span v-else>{{ error }}</span>
    </div>

    <!-------------------------------------------- Zone Task To do  ---------------------------------------->

    <p v-if="separator" class="title-block">{{ $t('TaskTodo') }}</p>

    <div :class="{ ContainerBody: separator }">
      <v-container fluid>
        <div v-for="event in filteredEventsNotDone" :key="event.id">
          <div class="task-card-block">
            <div class="d-flex  justify-space-between">
              <div class="task-card-sub-block">
                <v-checkbox
                  :true-value="true"
                  :false-value="false"
                  v-model="event.status"
                  :value="true"
                  class="custom-checkbox"
                  color="#45148F"
                  @change="handelCheckEvent(event)"
                ></v-checkbox>

                <div
                  class="badge-overdue"
                  v-if="
                    event.status !== 'Terminé' &&
                      new Date(event.end) <= new Date()
                  "
                >
                  <span>
                    {{ $t('overdue') }}
                  </span>
                </div>

                <div
                  class="badge-event-type"
                  :style="computedStyle(event)"
                  v-if="event?.type?.designation"
                >
                  <span>{{ event?.type?.designation }} </span>
                </div>

                <div class="event-name" @click="handelClickEvent(event)">
                  <span class="name">{{ event.name }}</span>
                  <!-- <span v-if="event.detail !== null" class="details">{{
                    event?.detail
                  }}</span> -->
                  <span v-if="event.assigned_user !== null" class="details">
                    ( {{ event?.assigned_user?.last_name }}
                    {{ event?.assigned_user?.first_name }})
                  </span>
                </div>
              </div>
              <!-- <div v-if="event?.type?.action_id" class="mt-4">
                <custom-drop-down-menu>
                  <template #header>
                    <div class="header-action-immo">
                      <font-awesome-icon :icon="['fas', 'ellipsis']" />
                    </div>
                  </template>
                  <template #body>
                    <div class="body-action-immo">
                      <action-event :event="event"></action-event>
                    </div>
                  </template>
                </custom-drop-down-menu>
              </div> -->
            </div>
          </div>
          <div class="event-date">
            {{ formaterDate(event.start) }}
          </div>
        </div>
      </v-container>
    </div>

    <v-divider class="HorizontalLine" v-if="separator"></v-divider>

    <!-------------------------------------------- Zone Task Done ---------------------------------------->
    <p v-if="separator" class="title-block">{{ $t('TaskDone') }}</p>

    <div :class="{ ContainerBodyChecked: separator }">
      <v-container fluid>
        <div v-for="event in filteredEventsDone" :key="event.id">
          <div class="task-card-block">
            <div class="task-card-sub-block">
              <v-checkbox
                class="custom-checkbox"
                :true-value="'Terminé'"
                :disabled="true"
                v-model="event.status"
              ></v-checkbox>
              <div
                class="badge-event-type"
                :style="computedStyle(event)"
                v-if="event?.type?.designation"
              >
                <span>{{ event?.type?.designation }} </span>
              </div>
              <div class="event-name">
                <span class="name-decoration">{{ event.name }}</span>
              </div>

              <div class="event-name">
                <!-- <span class="details" v-if="event.detail !== null"
                  >({{ event?.detail }})</span
                > -->
                <span v-if="event.assigned_user !== null" class="details">
                  ( {{ event?.assigned_user?.last_name }}
                  {{ event?.assigned_user?.first_name }})
                </span>
              </div>
            </div>
          </div>
          <div class="event-date">
            {{ formaterDate(event.start) }}
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
// import ActionEvent from './actionEvent.vue'
// import customDropDownMenu from './customDropDownMenu.vue'

export default {
  props: {
    events: { required: true },
    separator: { default: true },
    handelCheckFunction: { default: () => {} },
    error: { default: null }
  },
  computed: {
    filteredEventsDone() {
      return this.events.filter(event => event.status === 'Terminé')
    },
    filteredEventsNotDone() {
      return this.events.filter(event => event.status !== 'Terminé')
    },
    formaterDate() {
      return function(dateString) {
        const options = {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }

        const date = new Date(dateString)
        return date.toLocaleString('en-US', options)
      }
    },
    computedStyle() {
      return function(event) {
        return event && event.type && event.type.color
          ? 'background-color:' + event.type.color
          : 'background-color:#45148F'
      }
    }
  },
  methods: {
    handelClickEvent() {
      // this.$emit('clickEvent', event)
    },
    handelCheckEvent(event) {
      if (event.status == true) {
        this.handelCheckFunction(event)
      }
    }
  },
  components: {
    // customDropDownMenu,
    // ActionEvent
  }
}
</script>

<style scoped lang="scss">
.task-card {
  .title-block {
    margin-bottom: 1px;
    padding-top: 10px;
    color: #45148f;
    font-size: 15px;
    font-weight: 600;
    padding-left: 13px;
  }
  .container--fluid {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .task-card-block {
    flex-direction: column;
    align-items: center;
    .task-card-sub-block {
      display: flex;
      align-items: center;
      font-size: 11px;
    }
  }
  .badge-event-type {
    min-width: 75px;
    padding: 3px;
    white-space: nowrap;
    border-radius: 3px;
    height: 24px;
    color: white;
    margin-left: 5px;
    text-align: center;
    span {
      font-size: 12px;
      letter-spacing: 1px;
    }
  }
  .badge-overdue {
    background-color: #ef4444;
    width: 75px;
    border-radius: 3px;
    color: white;
    margin-left: 5px;
    text-align: center;
    padding: 3px;
    span {
      padding-top: 10px;
      font-size: 12px;
      letter-spacing: 1px;
    }
  }
  .event-name {
    margin-left: 5px;
    font-weight: 500;
    .name {
      font-weight: 500;
      margin-right: 3px;
    }
    .name-decoration {
      font-weight: 500;
      text-decoration: line-through;
    }
    .details {
      font-weight: 300;
    }
  }
  .event-date {
    font-size: 10px;
    margin-left: 35px;
    margin-top: -10px;
  }
  .header-action-immo {
    svg {
      color: #7f8c8d;
    }
  }
}

.HorizontalLine {
  width: 100%;
}
.ContainerBodyChecked {
  overflow-y: auto;
  height: 300px;
}
.ContainerBody {
  overflow-y: auto;

  height: 260px;
}
.ContainerBody,
.ContainerBodyChecked {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }
}
</style>
