<template>
  <div class="ContainerTaskPlaning">
    <!------------------- Header Task  ---------------->
    <v-toolbar class="Task-Container">
      <v-toolbar-title
        style="font-size: 13px;
"
      >
        {{
          new Date().toLocaleDateString(
            $i18n.locale === 'fr' ? 'fr-FR' : 'en-US',
            {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            }
          )
        }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

     <!---------------- Filter categorie -------------->

      <v-menu offset-y class="custom-menu">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            outlined
            class="Category-button"
            style=" font-size:10px;"
          >
            {{ $i18n.locale === 'fr' ? 'Categorie' : 'Category' }} :
            {{ selectedValue }}
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="scrollable-menu custom-list radio-filter-calendrier">
          
          <v-checkbox
              class="custom-checkbox"
              v-for="categorie in getTypeEvent"
              :key="categorie.id"
              v-model="categorie.checked"
              :label="categorie.designation"
              @change="FilterCalendrier"
              @click.stop
              color="#704ad1"
              hide-details
            >
            </v-checkbox>
          
           
          
        </v-list>
      </v-menu>
<!--------------------------- Filter Assign  --------------------->
      <v-menu offset-y class="custom-menu">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            outlined
            class="assign-button"
            style=" font-size:10px;"
          >
            {{ $i18n.locale === 'fr' ? 'Assigné' : 'Assigned' }} :
            {{ selectedValue }}
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="scrollable-menu custom-list radio-filter-calendrier" >
          <v-checkbox
              class="custom-checkbox"
              v-for="user in allUsers"
              :key="user.id"
              v-model="user.checked"
              :label="user.full_name"
              @change="FilterCalendrier"
              @click.stop
              color="#704ad1"
              hide-details
            ></v-checkbox>
          
        </v-list>
      </v-menu>

    </v-toolbar>

    <!------------------- Body Task  ---------------->
    <taskCard
      :events="geteventCalendrier"
      :handelCheckFunction="updateStatusTask"
      :error="getEventError"
    ></taskCard>
  </div>
</template>

<script>
import taskCard from '@/views/CRMILA26/Projects/immobilier/pipline/immoTable/component/taskCard.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    selectedValue: null,
    ischecked: true,
   
  }),

  computed: {
    ...mapGetters([
      "getTypeEvent",
      "allUsers",
      "geteventCalendrier", 
      "getEventError", 
    ]),
  },

  methods: {
    ...mapActions(['updateEventCalendrier','fetchAllEventCalender',]),

   
    FilterCalendrier() {
      let params = {}
      let typeE = this.getTypeEvent.filter(i => i.checked == true)
      if (typeE.length > 0) {
        params.type_events = typeE.map(i => i.id)
      }
      let User = this.allUsers.filter(i => i.checked == true)
      if (User.length > 0) {
        params.assigned_user_id = User.map(i => i.id)
      }
      this.fetchAllEventCalender(params)
    },

    getOrdinalSuffix(number) {
      const suffixes = ['th', 'st', 'nd', 'rd']
      const v = number % 100
      return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
    },

    selectItem(item) {
      this.selectedValue = item
    },
    selectItemCategorie(item) {
      this.selectedValue = item
    },
    async updateStatusTask(event) {
      let eventToUpdate = { ...event }
      eventToUpdate.status = 'Terminé'
      await this.updateEventCalendrier({
        body: eventToUpdate,
        event: event
      })
    }
  },

  async mounted() {},
  components: {
    taskCard
  }
}
</script>

<style lang="scss">
.radio-filter-calendrier {
  padding-left: 10px;
  margin-top: 0px;
  height: 220px;
  overflow-y: auto;
  .v-input--selection-controls {
    margin-top: 10px;
  }
  .v-label {
    margin-left: 2px;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: red !important;
  }
}
.ContainerTaskPlaning {
  border-top: none !important;
  .custom-checkbox {
    .mdi:before {
      color: #bdbcdb !important;
      font: lighter !important;
      font-family: 'Material Design Icons'; /* Vous pouvez spécifier votre police ici si nécessaire */
    }
  }

  .Task-Container.v-sheet.theme--light.v-toolbar {
    box-shadow: none !important;
    height: 65px !important;
    margin: 0px !important;
  }
}
</style>
<style scoped lang="scss">
.ContainerTaskPlaning {
  border: 1px solid #d8d8d8;
}

.custom-menu {
    scrollbar-width: thin;
    scrollbar-color: #999999 #f5f5f5; /* couleur de la poignée et de la piste */
}

.custom-list {
    /* Styles personnalisés pour la liste */
    /* Par exemple, ajuster la taille de la police, la couleur du texte, etc. */
}

.custom-list::-webkit-scrollbar {
    width: 6px; /* largeur de la barre de défilement */
}

.custom-list::-webkit-scrollbar-thumb {
    background-color: #999999; /* couleur de la poignée */
}

.custom-list::-webkit-scrollbar-track {
    background-color: #f5f5f5; /* couleur de la piste */
}


.Task-Container {
  font-size: 9px;
  border-bottom: 1px solid #d8d8d8;
  height: 500px;
}
.assign-button {
  margin-left: 5px;
  background-color: #f1f5f9;
  border: none;
}
.Category-button {
  background-color: #f1f5f9;
  border: none;
  height: 10px;
}

.scrollable-menu {
  max-height: 250px;
  overflow-y: auto;
  width: 200px;

  /* Ajout de styles de barre de défilement personnalisés */
  ::-webkit-scrollbar {
    width: 1px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f5f9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 2.5px;
  }
}
</style>
