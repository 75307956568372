<template>
  <v-row class="fill-height">
    <v-col>
      <!--------------------------------- Header de Calendrier  --------------------------------------------->
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            {{ $i18n.locale === 'fr' ? "Aujourd'hui" : 'Today' }}
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>{{
                  $i18n.locale === 'fr' ? 'Jour' : 'Day'
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>{{
                  $i18n.locale === 'fr' ? 'Semaine' : 'Week'
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>{{
                  $i18n.locale === 'fr' ? 'Mois' : 'Month'
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>{{
                  $i18n.locale === 'fr' ? '4 Jours' : '4 days '
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>

      <v-sheet flex class="custom-calendar">
        <!----------------------------------------- Affichage Calendrier ---------------------------------------->
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="geteventCalendrier"
          :event-color="getEventColor"
          :type="type"
          @click:day="handleAddEvent"
          @click:time="handleAddEvent"
          @click:event="showEvent"
          @change="getEvents"
          @mousedown:event="startDrag"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
        ></v-calendar>

        <template v-slot:event="{ event, timed, eventSummary }">
          <div class="v-event-draggable">
            <component :is="{ render: eventSummary }"></component>
          </div>
          <div
            v-if="timed"
            class="v-event-drag-bottom"
            @mousedown.stop="extendBottom(event)"
          ></div>
        </template>
        <!------------------------------- Modal d'affichages des events (show Event)  ---------------------------------------->
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <!----------------------------------------- Le contenu de modal d'evenement  ------------------------------------------>
          <v-card
            v-if="selectedEvent"
            color="white lighten-4"
            min-width="450px"
            min-height="200px"
            flat
          >
            <v-toolbar :color="selectedEvent?.color" dark>
              <v-toolbar-title style="margin-top:5px">
                <!-- <v-text-field v-model="eventName" label="Event Name"></v-text-field> -->

                <v-toolbar-title v-html="selectedEvent?.name"></v-toolbar-title>
              </v-toolbar-title>

              <v-spacer></v-spacer>
              <v-btn icon @click="handleModifierEvent(selectedEvent)">
                <v-icon color="grey" style="font-size: 21px;"
                  >mdi-pencil</v-icon
                >
              </v-btn>
              <v-btn icon @click.stop="deleteEvent(selectedEvent)">
                <v-icon color="grey" style="font-size: 21px;"
                  >mdi-delete</v-icon
                >
              </v-btn>
              <v-btn
                icon
                @click="selectedOpen = false"
                style="background-color: #f0f0f0;"
              >
                <v-icon color="grey" style="font-size: 21px;">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="custom-align-end" style="color: black;">
              <div style="margin-bottom: 10px;">
                <!-- <strong>{{ $i18n.locale === 'fr' ? 'Date' : 'Day' }} : </strong> -->
                <strong style="margin-right:8px"
                  ><font-awesome-icon :icon="['far', 'calendar']"
                /></strong>
                {{ formatDate(selectedEvent?.start) }} -
                {{ formatDate(selectedEvent?.end) }}
              </div>

              <div style="margin-bottom: 10px;">
                <!-- <strong>{{ $i18n.locale === 'fr' ? 'Heure' : 'Time' }} :</strong> -->
                <strong style="margin-right:8px"
                  ><font-awesome-icon :icon="['far', 'clock']"
                /></strong>
                {{ formatTime(selectedEvent?.start) }} -
                {{ formatTime(selectedEvent?.end) }}
              </div>

              <!-- <div style="margin-bottom: 10px;">
                <strong
                  >{{ $i18n.locale === 'fr' ? 'Désignation' : 'Designation' }} :
                </strong>
                {{ selectedEvent?.type?.designation }}
              </div> -->

              <div style="margin-bottom: 10px;">
                <strong
                  >{{ $i18n.locale === 'fr' ? 'Détails' : 'Details' }} :
                </strong>
                {{ selectedEvent?.detail }}
              </div>
            </v-card-text>
            <!-- <v-card-actions class="d-flex justify-end align-end">
              <v-btn text color="secondary" @click="selectedOpen = false">
                {{ $i18n.locale === 'fr' ? 'Annuler' : 'Cancel' }}
              </v-btn>
              <v-btn
                class="ml-2"
                text
                color="secondary"
                @click="selectedOpen = false">
                {{ $i18n.locale === 'fr' ? 'Enregistrer' : 'Save' }}
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-menu>
        <!------------------------------- Modal de Creation des events  ---------------------------------------->

        <v-menu
          v-model="SelectedCreateEvent"
          :close-on-content-click="false"
          :activator="selectedElementCreateEvent"
          offset-x
        >
          <v-card
            v-if="ModalCreateEvent"
            color="white lighten-4"
            min-width="400px"
            min-height="400px"
            flat
          >
            <v-toolbar>
              <v-toolbar-title style="margin-top:10px">
                <v-text-field
                  v-model="eventName"
                  style="color: black;"
                  label="(Sans Titre)"
                ></v-text-field>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="SelectedCreateEvent = false"
                style="background-color: #f0f0f0;"
              >
                <v-icon color="grey" style="font-size: 21px;">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text class="custom-align-end" style="color: black;">
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  :items="getallProject"
                  item-value="id"
                  item-text="title"
                  label="Project"
                  menu-props="{ maxHeight: 400, overflowY: 'auto' }"
                  @change="handleProjectChange"
                ></v-select>
              </v-col>

              <!-- <div style="margin-bottom: 10px;">
               
                <strong style="margin-right:8px"
                  ><font-awesome-icon :icon="['far', 'calendar']"/></strong
                >{{ currentDate }} - {{ currentDate }}
              </div> -->

              <v-row>
                <v-col cols="12" sm="6" md="6" class="mr-0" style="width: 5px;">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="currentDate"
                        label=""
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="6" md="6" class="ml-0" style="width: 5px;">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="currentDate"
                        label=""
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <div>
                <span style="display: inline-block;">
                  <font-awesome-icon
                    :icon="['far', 'clock']"
                    style="margin-right: 15px;"
                  />
                </span>
                <span style="display: inline-block;">
                  <v-select
                    v-model="currentTime"
                    :items="timeOptions"
                    :label="currentTime ? currentTime : '--:--'"
                  ></v-select>
                </span>
              </div>

              <div style="margin-bottom: 10px;">
                <strong
                  >{{ $i18n.locale === 'fr' ? 'Détails' : 'Details' }} :
                </strong>
                {{ selectedEvent?.detail }}
              </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-end align-end">
              <v-btn
                text
                color="secondary"
                @click="SelectedCreateEvent = false"
              >
                {{ $i18n.locale === 'fr' ? 'Annuler' : 'Cancel' }}
              </v-btn>
              <v-btn class="ml-2" text color="secondary" @click="SubmitEvent">
                {{ $i18n.locale === 'fr' ? 'Enregistrer' : 'Save' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <!-- ADD EVENT -->
        <v-dialog
          v-model="openModalAddEvent"
          max-width="800"
          persistent
          scrollable
        >
          <v-card>
            <v-card-title class="text-h6 title-modal">
              <v-label class="label-header">
                {{ $i18n.locale === 'fr' ? 'Ajouter événement' : 'Add event' }}
              </v-label>
              <v-btn
                class="btn-close-header"
                icon
                @click="closeDialog('openModalAddEvent')"
                :title="$t('close')"
                color="#5C2DD3"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider class="line-hr-header"></v-divider>

            <v-card-text class="body-card center-text  mt-2">
              <v-form ref="openModalAddEvent" class="mt-2">
                <!-- NOM EVENT -->
                <v-row class="row">
                  <v-col>
                    <v-text-field
                      :label="$t('name')"
                      dense
                      :persistent-placeholder="true"
                      outlined
                      color="#5C2DD3"
                      v-model="eventToAdd.name"
                      item-color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- DESCRIPTION -->
                <v-row class="row">
                  <v-col>
                    <v-textarea
                      dense
                      label="Description"
                      v-model="eventToAdd.detail"
                      :persistent-placeholder="true"
                      rows="3"
                      outlined
                      color="#5C2DD3"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!----- Premier composant pour la date de début --------->
                <div style="display: flex; justify-content: space-between;">
                  <!-- Vue CTK Date Time Picker pour la date de début -->
                  <vue-ctk-date-time-picker
                    style="width: calc(50% - 2%);"
                    v-model="eventToAdd.start"
                    color="#45148F"
                    :inline="false"
                    :buttonNowTranslation="'Today'"
                    inputSize="sm"
                    buttonColor="#45148F"
                    locale="fr"
                    label="Start Date"
                    :no-value-to-custom-elem="true"
                    @input="checkRulesDate(['dateStart', 'dateEnd'])"
                  >
                    <v-text-field
                      v-model="eventToAdd.start"
                      :label="$t('date_debut')"
                      @click:clear="eventToAdd.start = null"
                      outlined
                      readonly
                      clearable
                      dense
                      ref="dateStart"
                      :persistent-placeholder="true"
                      :placeholder="$t('msgSelectDate')"
                      prepend-inner-icon="mdi-calendar"
                      color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('date_debut') + ' ' + $t('msgOblg')
                      ]"
                    ></v-text-field>
                  </vue-ctk-date-time-picker>

                  <!-- Deuxième composant pour la date de fin -->
                  <!-- Vue CTK Date Time Picker pour la date de fin -->
                  <vue-ctk-date-time-picker
                    style="width: calc(50% - 2%); "
                    v-model="eventToAdd.end"
                    color="#45148F"
                    :inline="false"
                    :buttonNowTranslation="'Today'"
                    inputSize="sm"
                    buttonColor="#45148F"
                    locale="fr"
                    label="End Date"
                    :no-value-to-custom-elem="true"
                    @input="checkRulesDate('dateEnd')"
                    class="custom-ctk-component right-position "
                  >
                    <v-text-field
                      v-model="eventToAdd.end"
                      :label="$t('date_fin')"
                      @click:clear="eventToAdd.end = null"
                      outlined
                      readonly
                      clearable
                      ref="dateEnd"
                      dense
                      :persistent-placeholder="true"
                      :placeholder="$t('msgSelectDate')"
                      prepend-inner-icon="mdi-calendar"
                      color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('date_fin') + ' ' + $t('msgOblg')
                      ]"
                    ></v-text-field>
                  </vue-ctk-date-time-picker>
                </div>

                <!-------------------- Liste des Type de projet ------------------->

                <!-- <v-row>
                  <v-col>
                    <v-select
                      v-model="eventToAdd.type_projet"
                      :label="
                        $i18n.locale == 'fr' ? 'Type du projet' : 'Project type'
                      "
                      dense
                      return-object
                      :no-data-text="
                        $i18n.locale == 'fr'
                          ? 'Aucun Type du projet trouvé'
                          : 'None Project type found'
                      "
                      item-text="name"
                      item-value="id"
                      :items="getProjectsTypes"
                      outlined
                      :loading="getProjectsTypesLoading"
                      :persistent-placeholder="true"
                      :menu-props="{ bottom: true, offsetY: true }"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v =>
                          !!v ||
                          ($i18n.locale == 'fr'
                            ? 'Type du projet'
                            : 'Project type') +
                            ' ' +
                            $t('msgOblg')
                      ]"
                    ></v-select>
                  </v-col>
                </v-row> -->

                <!-- STATUS EVENT -->
                <v-row>
                  <v-col>
                    <v-autocomplete
                      :clearable="true"
                      color="#5C2DD3"
                      :placeholder="$t('event_status')"
                      item-color="#5C2DD3"
                      v-model="eventToAdd.status"
                      :items="getEventStatus"
                      :persistent-placeholder="true"
                      :label="$t('event_status')"
                      item-text="text"
                      dense
                      item-value="value"
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('event_status') + ' ' + $t('msgOblg')
                      ]"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <!-- ASSIGN USER TO EVENT -->
                <v-row>
                  <v-col>
                    <v-autocomplete
                      :clearable="true"
                      color="#5C2DD3"
                      :placeholder="$t('assigned_to')"
                      item-color="#5C2DD3"
                      v-model="eventToAdd.assigned_user_id"
                      :items="allUsers"
                      :persistent-placeholder="true"
                      :label="$t('assigned_to')"
                      item-text="full_name"
                      dense
                      item-value="id"
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('assigned_to') + ' ' + $t('msgOblg')
                      ]"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <!-------------------- Liste des projets ------------------->
                <v-row>
                  <v-col>
                    <v-select
                      v-model="eventToAdd.Projet"
                      :label="$i18n.locale == 'fr' ? 'Projet' : 'Project'"
                      dense
                      return-object
                      :no-data-text="
                        $i18n.locale == 'fr'
                          ? 'Aucun projet trouvé'
                          : 'None Project found'
                      "
                      item-text="title"
                      item-value="id"
                      :items="getallProject"
                      outlined
                      :loading="getProjectsTypesLoading"
                      :persistent-placeholder="true"
                      :menu-props="{ bottom: true, offsetY: true }"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v =>
                          !!v ||
                          ($i18n.locale == 'fr' ? 'Projet' : 'Project') +
                            ' ' +
                            $t('msgOblg')
                      ]"
                    ></v-select>
                  </v-col>
                </v-row>
                <!---------------------------------------------- TYPE EVENT --------------------------------------------->
                <v-row>
                  <v-col>
                    <v-autocomplete
                      :clearable="true"
                      color="#5C2DD3"
                      @input="changeFieldsTypeEvent"
                      :loading="getTypeEventLoading"
                      :placeholder="
                        $t('searchMsg', {
                          msg:
                            $i18n.locale === 'fr'
                              ? 'type événement'
                              : 'Event type'
                        })
                      "
                      item-color="#5C2DD3"
                      v-model="eventToAdd.type"
                      :items="getTypeEvent"
                      :persistent-placeholder="true"
                      :label="
                        $i18n.locale === 'fr' ? 'Type événement' : 'Event type'
                      "
                      item-text="designation"
                      dense
                      return-object
                      item-value="id"
                      :no-data-text="
                        $t('noDataOptionMsg', {
                          option:
                            $i18n.locale === 'fr'
                              ? 'type événement'
                              : 'Event type'
                        })
                      "
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <!-- CHAMP DYNAMIQUE  -->
                <div
                  v-if="
                    eventToAdd.type &&
                      eventToAdd.type.fields &&
                      eventToAdd.type.fields.length
                  "
                >
                  <v-row
                    v-for="field in eventToAdd.type.fields"
                    :key="'field-add-' + field.id + `${eventToAdd.type.id}`"
                  >
                    <v-col>
                      <div class="value">
                        <EditableCustomFieldForm
                          :readonly="false"
                          :field="field"
                          :value="field.value"
                          :typeRessource="eventToAdd.type.fields"
                          @updateFunction="updateFunction"
                          :fieldKeyEdit="false"
                          :required="field.required"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
            <v-divider class="line-hr-footer"></v-divider>
            <v-card-actions class="actions-msg-btn-modal">
              <div class="message">
                <div v-if="getEventLoading" class="loading">
                  {{ $t('loading') }}
                </div>
                <div v-if="getEventError" class="error-msg">
                  <ul v-if="Array.isArray(getEventError)">
                    <li v-for="(e, index) in getEventError" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <span v-else>{{ getEventError }}</span>
                </div>
              </div>

              <v-spacer></v-spacer>
              <v-btn
                color="#5C2DD3"
                dark
                @click.prevent.stop="ajouterEvent"
                :loading="loading"
                small
              >
                {{ $t('btnValider') }}
              </v-btn>
              <v-btn text @click="closeDialog('openModalAddEvent')" small>{{
                $t('btnCancel')
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!------------------------------- Modal de Modification des events  ---------------------------------------->

        <v-dialog
          v-model="openModalUpdateEvent"
          max-width="600"
          persistent
          scrollable
        >
          <v-card>
            <v-card-title class="text-h6 title-modal">
              <v-label class="label-header">
                {{
                  $i18n.locale === 'fr' ? 'Modifier événement' : 'Edit event'
                }}
              </v-label>
              <v-btn
                class="btn-close-header"
                icon
                @click="closeDialog('openModalUpdateEvent')"
                :title="$t('close')"
                color="#5C2DD3"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider class="line-hr-header"></v-divider>

            <v-card-text class="body-card center-text  mt-2">
              <v-form
                ref="openModalUpdateEvent"
                class="mt-2"
                v-if="eventToUpdate"
              >
                <!-- NOM EVENT -->
                <v-row class="row">
                  <v-col>
                    <v-text-field
                      :label="$t('name')"
                      dense
                      :persistent-placeholder="true"
                      outlined
                      color="#5C2DD3"
                      v-model="eventToUpdate.name"
                      item-color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- DESCRIPTION -->
                <v-row class="row">
                  <v-col>
                    <v-textarea
                      dense
                      label="Description"
                      v-model="eventToUpdate.detail"
                      :persistent-placeholder="true"
                      rows="3"
                      outlined
                      color="#5C2DD3"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!-- DATE DEBUT / DATE FIN -->
                <!-- Premier composant pour la date de début -->
                <div style="display: flex; justify-content: space-between;">
                  <!-- Vue CTK Date Time Picker pour la date de début -->
                  <vue-ctk-date-time-picker
                    style="width: calc(50% - 2%);"
                    v-model="eventToUpdate.start"
                    color="#45148F"
                    :inline="false"
                    :buttonNowTranslation="'Today'"
                    inputSize="sm"
                    buttonColor="#45148F"
                    locale="fr"
                    label="Start Date"
                    :no-value-to-custom-elem="true"
                    @input="
                      checkRulesDate(['dateStartUpdate', 'dateEndUpdate'])
                    "
                  >
                    <v-text-field
                      v-model="eventToUpdate.start"
                      :label="$t('date_debut')"
                      @click:clear="eventToUpdate.start = null"
                      outlined
                      readonly
                      clearable
                      dense
                      ref="dateStartUpdate"
                      :persistent-placeholder="true"
                      :placeholder="$t('msgSelectDate')"
                      prepend-inner-icon="mdi-calendar"
                      color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('date_debut') + ' ' + $t('msgOblg')
                      ]"
                    ></v-text-field>
                  </vue-ctk-date-time-picker>

                  <!-- Deuxième composant pour la date de fin -->
                  <!-- Vue CTK Date Time Picker pour la date de fin -->
                  <vue-ctk-date-time-picker
                    style="width: calc(50% - 2%); margin-left: 4%;"
                    v-model="eventToUpdate.end"
                    color="#45148F"
                    :inline="false"
                    :buttonNowTranslation="'Today'"
                    inputSize="sm"
                    buttonColor="#45148F"
                    locale="fr"
                    label="End Date"
                    :no-value-to-custom-elem="true"
                    @input="checkRulesDate('dateEndUpdate')"
                    class="custom-ctk-component right-position "
                  >
                    <v-text-field
                      v-model="eventToUpdate.end"
                      :label="$t('date_fin')"
                      @click:clear="eventToUpdate.end = null"
                      outlined
                      readonly
                      clearable
                      ref="dateEndUpdate"
                      dense
                      :persistent-placeholder="true"
                      :placeholder="$t('msgSelectDate')"
                      prepend-inner-icon="mdi-calendar"
                      color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('date_fin') + ' ' + $t('msgOblg')
                      ]"
                    ></v-text-field>
                  </vue-ctk-date-time-picker>
                </div>
                <!-- STATUS EVENT -->
                <v-row>
                  <v-col>
                    <v-autocomplete
                      :clearable="true"
                      color="#5C2DD3"
                      :placeholder="$t('event_status')"
                      item-color="#5C2DD3"
                      v-model="eventToUpdate.status"
                      :items="getEventStatus"
                      :persistent-placeholder="true"
                      :label="$t('event_status')"
                      item-text="text"
                      dense
                      item-value="value"
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('event_status') + ' ' + $t('msgOblg')
                      ]"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <!-- ASSIGN USER TO EVENT -->
                <v-row>
                  <v-col>
                    <v-autocomplete
                      :clearable="true"
                      color="#5C2DD3"
                      :placeholder="$t('assigned_to')"
                      item-color="#5C2DD3"
                      v-model="eventToUpdate.assigned_user_id"
                      :items="allUsers"
                      :persistent-placeholder="true"
                      :label="$t('assigned_to')"
                      item-text="full_name"
                      dense
                      item-value="id"
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('assigned_to') + ' ' + $t('msgOblg')
                      ]"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <!-------------------- Liste des projets ------------------->
                <v-row>
                  <v-col>
                    <v-select
                      v-model="eventToUpdate.projet_id"
                      :label="$i18n.locale == 'fr' ? 'Projet' : 'Project'"
                      dense
                      return-object
                      :no-data-text="
                        $i18n.locale == 'fr'
                          ? 'Aucun projet trouvé'
                          : 'None Project found'
                      "
                      item-text="title"
                      item-value="id"
                      :items="getallProject"
                      outlined
                      :loading="getProjectsTypesLoading"
                      :persistent-placeholder="true"
                      :menu-props="{ bottom: true, offsetY: true }"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v =>
                          !!v ||
                          ($i18n.locale == 'fr' ? 'Projet' : 'Project') +
                            ' ' +
                            $t('msgOblg')
                      ]"
                    ></v-select>
                  </v-col>
                </v-row>

                <!-- TYPE EVENT -->
                <v-row>
                  <v-col>
                    <v-autocomplete
                      :clearable="true"
                      color="#5C2DD3"
                      disabled
                      :loading="getTypeEventLoading"
                      :placeholder="
                        $t('searchMsg', {
                          msg:
                            $i18n.locale === 'fr'
                              ? 'type événement'
                              : 'Event type'
                        })
                      "
                      item-color="#5C2DD3"
                      v-model="eventToUpdate.type"
                      :items="getTypeEvent"
                      :persistent-placeholder="true"
                      :label="
                        $i18n.locale === 'fr' ? 'Type événement' : 'Event type'
                      "
                      item-text="designation"
                      dense
                      return-object
                      item-value="id"
                      :no-data-text="
                        $t('noDataOptionMsg', {
                          option:
                            $i18n.locale === 'fr'
                              ? 'type événement'
                              : 'Event type'
                        })
                      "
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <!-- CHAMP DYNAMIQUE  -->
                <div
                  v-if="
                    eventToUpdate &&
                      eventToUpdate.type &&
                      eventToUpdate.fields &&
                      eventToUpdate.fields.length
                  "
                >
                  <v-row
                    v-for="field in eventToUpdate.fields"
                    :key="
                      'field-update-' + field.id + `${eventToUpdate.type.id}`
                    "
                  >
                    <v-col>
                      <div class="value">
                        <EditableCustomFieldForm
                          :readonly="false"
                          :field="field"
                          :value="field.value"
                          :typeRessource="eventToUpdate.fields"
                          :fieldKeyEdit="false"
                          @updateFunction="updateFunctionUpdate"
                          :required="field.required"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
            <v-divider class="line-hr-footer"></v-divider>
            <v-card-actions class="actions-msg-btn-modal">
              <div class="message">
                <div v-if="getEventLoading" class="loading">
                  {{ $t('loading') }}
                </div>
                <div v-if="getEventError" class="error-msg">
                  <ul v-if="Array.isArray(getEventError)">
                    <li v-for="(e, index) in getEventError" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <span v-else>{{ getEventError }}</span>
                </div>
              </div>

              <v-spacer></v-spacer>
              <v-btn
                color="#5C2DD3"
                dark
                @click.prevent.stop="modifierEvent"
                :loading="loading"
                small
              >
                {{ $t('btnValider') }}
              </v-btn>
              <v-btn text @click="closeDialog('openModalUpdateEvent')" small>{{
                $t('btnCancel')
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')
export default {
  name: 'calendrier',
  data: () => ({
    typeEventToAdd: { designation: null, detail: null, type_projet: null },
    initLoading: false,
    openModalAddEvent: false,
    loading: false,
    eventToAdd: {
      name: null,
      detail: null,
      start: null,
      end: null,
      Projet: null,
      type: null,
      fields: [],
      status: 'En attente'
    },
    ModalDeleteEvent: false,
    eventToDelete: null,
    openModalUpdateEvent: false,
    eventToUpdate: null,
    eventToUpdateOriginal: null,
    selectedDateTimeDateStart: null,
    selectedDateTimeTimeStart: null,
    selectedDateTimeDateEnd: null,
    selectedDateTimeTimeEnd: null,
    date: new Date(),
    timeOptions: [],
    menu1: false,
    modal: false,
    menu2: false,
    selectedProjectId: null,
    currentDate: '',
    currentTime: '--:--',
    eventName: '(Sans Titre)',
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days'
    },
    ModalCreateEvent: false,
    ModalUpdateEvent: false,
    selectedEvent: {},
    selectedElement: null,
    selectedElementCreateEvent: null,
    selectedElementUpdateEvent: null,
    selectedOpen: false,
    SelectedCreateEvent: false,
    SelectedUpdateEvent: false,
    EventSource: [],
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null
  }),

  components: {
    customColorPicker: () => import('@/components/customColorPicker.vue'),
    EditableCustomFieldForm: () =>
      import('@/components/CrmIla26Component/EditableCustomFieldForm')
  },
  mounted() {
    this.fetchAllProject()
    this.getAllUsers()
    this.$refs.calendar.checkChange()
    this.typeToLabel.month = this.$i18n.locale === 'fr' ? 'Mois' : 'Month'
    this.typeToLabel.week = this.$i18n.locale === 'fr' ? 'Semaine' : 'Week'
    this.typeToLabel.day = this.$i18n.locale === 'fr' ? 'Jour' : 'Day'
    this.typeToLabel['4day'] = this.$i18n.locale === 'fr' ? '4 Jours' : '4 Days'
  },

  computed: {
    ...mapGetters([
      'geteventCalendrier',
      'getallProject',
      'getEventLoading',
      'getEventError',
      'getEvent',
      'getEventOfProject',
      'getTypeEventLoading',
      'getTypeEvent',
      'getProjectsTypes',
      'getProjectsTypesLoading',
      'getEventStatus',
      'allUsers'
    ])
  },

  methods: {
    ...mapActions([
      'fetchAllEvent',
      'addEventCalendrier',
      'deletedEventCalendrier',
      'updateEvent',
      'createFakeEvent',
      'fetchAllProject',
      'addEvent',
      'deletedEvent',
      'resetErrorEvent',
      'fetchAllTypeEvent',
      'updateEventCalendrier',
      'DeleteFakeEvent',
      'getAllUsers'
    ]),
    checkRulesDate(ref) {
      if (Array.isArray(ref)) {
        ref.forEach((item, index) => {
          setTimeout(() => {
            this.$refs[item].focus()
          }, index * 1000)
        })
      } else {
        this.$refs[ref].focus()
      }
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format('YYYY-MM-DD HH:mm')
    },

    updateFunction(field) {
      this.eventToAdd.fields = field
    },

    changeFieldsTypeEvent() {},

    handleAddEvent(event) {
      this.openModalAddEvent = true
      this.generateTimeOptions()
      this.fetchAllProject()
      this.createFakeEvent({
        start_date: event.date,
        start_time: event.time
      })
      this.currentDate = event.date
      this.currentTime = event.time
      const mouse = this.toTime(0)
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start
        this.dragTime = mouse - start
      } else {
        this.createStart = this.currentDate
      }
    },
    async ajouterEvent() {
      if (this.$refs.openModalAddEvent.validate()) {
        this.loading = true

        this.eventToAdd.start = this.eventToAdd.start
          ? moment(this.eventToAdd.start, 'YYYY-MM-DD HH:mm').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : this.eventToAdd.end
        this.eventToAdd.end = this.eventToAdd.end
          ? moment(this.eventToAdd.end, 'YYYY-MM-DD HH:mm').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : this.eventToAdd.end
        const response = await this.addEventCalendrier(this.eventToAdd)
        if (response) {
          this.closeDialog('openModalAddEvent')
        }
        this.loading = false
      }
    },

    async CreateEvent(event, nativeEvent) {
      this.generateTimeOptions()
      this.createFakeEvent({
        start_date: event.date,
        start_time: event.time
      })
      this.currentDate = event.date
      this.currentTime = event.time
      const mouse = this.toTime(0)

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start
        this.dragTime = mouse - start
      } else {
        this.createStart = this.currentDate

        this.SelectedCreateEvent = true
        this.ModalCreateEvent = true
        const open = () => {
          this.selectedEvent = event
          this.selectedElementCreateEvent = nativeEvent.target
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.SelectedCreateEvent = true))
          )
        }

        if (this.SelectedCreateEvent) {
          this.SelectedCreateEvent = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      }
    },

    async SubmitEvent() {
      const endDate = new Date(this.createStart)
      endDate.setHours(endDate.getHours() + 1) // Par exemple, ajoutez une heure à la date de début
      // const formattedEndDate = this.formatDate(endDate)

      const eventName = this.eventName
      const currentDate = this.currentDate
      const detail = this.detail

      this.createEvent = {
        projet_id: this.selectedProjectId,
        name: eventName,
        color: 'blue',
        detail: detail,
        start_date:
          currentDate +
          (this.currentTime ? ' ' + this.currentTime : ' 00:00:00'),
        end_date:
          currentDate +
          (this.currentTime ? ' ' + this.currentTime : ' 00:00:00'),
        timed: true,
        assigned_user_id: null
      }

      const response = await this.addEventCalendrier(this.createEvent)
      if (response) {
        this.SelectedCreateEvent = false
        this.ModalCreateEvent = false
      }
    },

    updateDateTimeStart() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDateStart && this.selectedDateTimeTimeStart) {
        if (this.openModalAddEvent) {
          this.eventToAdd.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
        } else {
          this.eventToUpdate.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
        }
      } else if (
        this.selectedDateTimeDateStart &&
        !this.selectedDateTimeTimeStart
      ) {
        if (this.openModalAddEvent) {
          this.eventToAdd.start = `${this.selectedDateTimeDateStart} 00:00`
        } else {
          this.eventToUpdate.start = `${this.selectedDateTimeDateStart} 00:00`
        }
      } else if (
        !this.selectedDateTimeDateStart &&
        this.selectedDateTimeTimeStart
      ) {
        this.selectedDateTimeDateStart = new Date().toJSON().slice(0, 10)

        if (this.openModalAddEvent) {
          this.eventToAdd.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
        } else {
          this.eventToUpdate.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
        }
      }
    },
    updateDateTimeEnd() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDateEnd && this.selectedDateTimeTimeEnd) {
        if (this.openModalAddEvent) {
          this.eventToAdd.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
        } else {
          this.eventToUpdate.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
        }
      } else if (
        this.selectedDateTimeDateEnd &&
        !this.selectedDateTimeTimeEnd
      ) {
        if (this.openModalAddEvent) {
          this.eventToAdd.end = `${this.selectedDateTimeDateEnd} 00:00`
        } else {
          this.eventToUpdate.end = `${this.selectedDateTimeDateEnd} 00:00`
        }
      } else if (
        !this.selectedDateTimeDateEnd &&
        this.selectedDateTimeTimeEnd
      ) {
        this.selectedDateTimeDateEnd = new Date().toJSON().slice(0, 10)
        if (this.openModalAddEvent) {
          this.eventToAdd.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
        } else {
          this.eventToUpdate.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
        }
      }
    },
    handleDatePicker(date, ref) {
      if (date) {
        this.$refs[ref].resetValidation()
      }
    },

    updateFunctionUpdate(data) {
      this.eventToUpdate.fieldsUpdate = data
    },

    handleModifierEvent(event) {
      this.eventToUpdate = { ...event }
      this.eventToUpdateOriginal = event
      this.openModalUpdateEvent = true
      this.selectedDateTimeDateStart =
        event && event.start ? event.start.split(' ')[0] : event.start
      this.selectedDateTimeTimeStart =
        event && event.start ? event.start.split(' ')[1] : event.start
      this.selectedDateTimeDateEnd =
        event && event.end ? event.end.split(' ')[0] : event.end
      this.selectedDateTimeTimeEnd =
        event && event.end ? event.end.split(' ')[1] : event.end
    },
    async modifierEvent() {
      if (this.$refs.openModalUpdateEvent.validate()) {
        this.loading = true
        this.eventToUpdate.start = this.eventToUpdate.start
          ? moment(this.eventToUpdate.start, 'YYYY-MM-DD HH:mm').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : this.eventToUpdate.end
        this.eventToUpdate.end = this.eventToUpdate.end
          ? moment(this.eventToUpdate.end, 'YYYY-MM-DD HH:mm').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : this.eventToUpdate.end
        const response = await this.updateEventCalendrier({
          body: this.eventToUpdate,
          event: this.eventToUpdateOriginal
        })

        if (response) {
          this.closeDialog('openModalUpdateEvent')
        }
        this.loading = false
      }
    },

    closeDialog(ref) {
      this[ref] = false
      if (ref == 'openModalAddEvent') {
        this.$refs.openModalAddEvent.resetValidation()
        this.DeleteFakeEvent(this.eventToAdd)
        this.$forceUpdate()
      }
      if (ref == 'openModalUpdateEvent') {
        this.$refs.openModalUpdateEvent.resetValidation()
        let project_id =
          this.project && this.project.id
            ? this.project.id
            : this.$route.name == 'project' &&
              this.$route.params &&
              this.$route.params.id
            ? this.$route.params.id
            : null
        if (project_id != null) {
          this.fetchOneTypeEventOfProject(project_id)
        }
        this.eventToUpdate = null
      }
      this.resetErrorEvent()
      this.resetModal()
      this.fetchAllTypeEvent()
    },
    resetModal() {
      this.eventToAdd = {
        name: null,
        detail: null,
        start: null,
        end: null,
        type: null,
        fields: [],
        status: 'En attente'
      }
    },

    showEvent({ nativeEvent, event }) {
      this.SelectedCreateEvent = false
      this.ModalCreateEvent = false

      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        )
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    generateTimeOptions() {
      const hours = Array.from({ length: 12 }, (_, i) => i + 1)
      const minutes = ['00', '15', '30', '45']
      const periods = ['AM', 'PM']
      const options = []

      hours.forEach(hour => {
        minutes.forEach(minute => {
          periods.forEach(period => {
            options.push(`${hour}:${minute} ${period}`)
          })
        })
      })

      this.timeOptions = options
    },

    handleProjectChange(selectedProjectId) {
      this.selectedProjectId = selectedProjectId
    },

    formatDate(dateTime) {
      if (!dateTime) {
        return '' // ou une valeur par défaut si dateTime est null ou undefined
      }

      const dateObject = new Date(dateTime)

      if (isNaN(dateObject.getTime())) {
        return 'Invalid Date'
      }

      const options = { year: 'numeric', month: 'short', day: 'numeric' }
      return new Intl.DateTimeFormat(this.$i18n.locale, options).format(
        dateObject
      )
    },

    formatTime(dateTime) {
      if (!dateTime) {
        return '' // ou une valeur par défaut si dateTime est null ou undefined
      }

      const dateObject = new Date(dateTime)

      if (isNaN(dateObject.getTime())) {
        return 'Invalid Date'
      }

      const options = { hour: 'numeric', minute: 'numeric', hour12: true }
      return new Intl.DateTimeFormat(this.$i18n.locale, options).format(
        dateObject
      )
    },

    // Methode pour supprimer un event de calendrier //
    deleteEvent(selectedEvent) {
      if (selectedEvent.id !== -1) {
        this.deletedEventCalendrier(selectedEvent.id)
        this.selectedOpen = false
      }
    },

    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },

    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16)
      const r = (rgb >> 16) & 0xff
      const g = (rgb >> 8) & 0xff
      const b = (rgb >> 0) & 0xff

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color
    },

    getEvents() {
      //calll'api
    },

    extendBottom(event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },

    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },

    // async startTime(tms, arg) {
    //   const mouse = this.toTime(tms)

    //   if (this.dragEvent && this.dragTime === null) {
    //     const start = this.dragEvent.start
    //     this.dragTime = mouse - start
    //   } else {
    //     this.createStart = this.roundTime(mouse)

    //     const startDateWithTime = new Date(this.createStart)

    //     startDateWithTime.setHours(startDateWithTime.getHours() + 1)

    //     const formattedStartDate = startDateWithTime
    //       .toISOString()
    //       .slice(0, 19)
    //       .replace('T', ' ')

    //     const randomEventName = this.generateRandomEventName()

    //     this.createEvent = {
    //       name: randomEventName,
    //       color: 'blue',
    //       detail: '',
    //       start_date: formattedStartDate,
    //       end_date: formattedStartDate,
    //       timed: true
    //     }
    //     const reponse = await this.addEventCalendrier(this.createEvent)
    //     if (reponse) {
    //       this.SelectedCreateEvent = true
    //       this.ModalCreateEvent = true
    //     }
    //   }
    // },

    endDrag() {
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
      this.eventName = ''
    },

    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime()
    },

    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.geteventCalendrier.indexOf(this.createEvent)
          if (i !== -1) {
            this.geteventCalendrier.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
      this.eventName = ''
    },

    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)]
    }
  },

  watch: {
    // Écouter les changements dans la date de début
    'eventToAdd.start': function(newVal) {
      this.eventToAdd.end = newVal
    },
    selectedDateTimeDateStart() {
      this.updateDateTimeStart()
    },
    selectedDateTimeTimeStart() {
      this.updateDateTimeStart()
    },
    selectedDateTimeDateEnd() {
      this.updateDateTimeEnd()
    },
    selectedDateTimeTimeEnd() {
      this.updateDateTimeEnd()
    }
  },

  filters: {
    datetime: function(value, format = 'DD-MM-YYYY HH:mm') {
      if (!value) return ''
      return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format)
    }
  }
}
</script>

<style>
.v-input__slot:before {
  display: none;
}
</style>

<style scoped lang="scss">
.v-sheet.theme--light.v-toolbar {
  background-color: white !important;
  color: black !important;
  box-shadow: none;
}
.v-sheet.theme--dark.v-toolbar.blue {
  background-color: white !important;
  color: black !important;
  box-shadow: none;
}

.custom-calendar {
  height: calc(100% - 125px);
}

.custom-align-end {
  min-height: 200px;
  /* Ajustez selon vos besoins */
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
