import { render, staticRenderFns } from "./Task.vue?vue&type=template&id=454ba950&scoped=true"
import script from "./Task.vue?vue&type=script&lang=js"
export * from "./Task.vue?vue&type=script&lang=js"
import style0 from "./Task.vue?vue&type=style&index=0&id=454ba950&prod&lang=scss"
import style1 from "./Task.vue?vue&type=style&index=1&id=454ba950&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "454ba950",
  null
  
)

export default component.exports