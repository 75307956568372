import { render, staticRenderFns } from "./SideBarCalendar.vue?vue&type=template&id=2e58da52&scoped=true"
import script from "./SideBarCalendar.vue?vue&type=script&lang=js"
export * from "./SideBarCalendar.vue?vue&type=script&lang=js"
import style0 from "./SideBarCalendar.vue?vue&type=style&index=0&id=2e58da52&prod&lang=scss"
import style1 from "./SideBarCalendar.vue?vue&type=style&index=1&id=2e58da52&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e58da52",
  null
  
)

export default component.exports