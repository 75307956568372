<template>
  <div class="content-planning">
    <!------------------------------------------ Transition Left Side Bar  ------------------------------------>
    <div
      v-if="!getCurentProjectTypeImmo"
      class="list-planning"
      :class="{ 'menu-hidden': hiddenContentPipeline }"
    >
      <div
        class="title-planning"
        :class="{ 'menu-hidden': hiddenContentPipeline }"
      >
        <SideBarCalender />
      </div>
      <div class="transition-pipeline">
        <div
          v-if="!hiddenContentPipeline"
          class="flech-back-pipeline "
          @click="hiddenMenuPipeline"
        >
          <div class="icon-back">
            <v-icon class="cursor-pointer color-crm " font="mdi"
              >mdi-chevron-left</v-icon
            >
          </div>
        </div>
        <div
          v-else
          class="flech-back-pipeline-right"
          @click="hiddenMenuPipeline"
        >
          <div class="icon-back">
            <v-icon class="cursor-pointer color-crm" font="mdi"
              >mdi-chevron-right</v-icon
            >
          </div>
        </div>
      </div>
    </div>
    <!------------------- Header (titre Planing d'intervention et Button Choisir une action)  -->
    <div
      class="block-content-planning"
      :class="{ 'menu-hidden': hiddenContentPipeline }"
    >
      <router-view />
      <CalendarTifyV2 v-if="getCurentProjectTypeImmo" />
      <CalendarTify v-else />
    </div>

    <div class="Task_Calendrier" v-if="getCurentProjectTypeImmo">
      <TaskCalendrier :geteventCalendrier="geteventCalendrier" />

      <!------------------------------------------ Transition Right Side Bar ------------------------------------>
      <!-- <div class="transition-pipeline">
        <div
          v-if="!hiddenContentPipeline"
          class="flech-back-pipeline "
          @click="hiddenMenuPipeline"
        >
          <div class="icon-back">
            <v-icon class="cursor-pointer color-crm " font="mdi"
              >mdi-chevron-left</v-icon
            >
          </div>
        </div>
        <div
          v-else
          class="flech-back-pipeline-right"
          @click="hiddenMenuPipeline"
        >
          <div class="icon-back">
            <v-icon class="cursor-pointer color-crm" font="mdi"
              >mdi-chevron-right</v-icon
            >
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SideBarCalender from './Agenda/SideBarCalendar.vue'
import CalendarTify from './Agenda/CalendarTify.vue'
import CalendarTifyV2 from './Agenda/CalendarTifyV2.vue'
import TaskCalendrier from './Agenda/Task.vue'

export default {
  data() {
    return {
      hiddenContentPipeline: false,
      items: [
        {
          title:
            this.$i18n.locale === 'fr'
              ? 'Prestation de service'
              : 'Service provision',
          count: 17
        },
        {
          title:
            this.$i18n.locale === 'fr'
              ? 'Prestation de service'
              : 'Service provision',
          count: 17
        },
        {
          title:
            this.$i18n.locale === 'fr'
              ? 'Prestation de service'
              : 'Service provision',
          count: 17
        },
        {
          title:
            this.$i18n.locale === 'fr'
              ? 'Prestation de service'
              : 'Service provision',
          count: 17
        },
        {
          title:
            this.$i18n.locale === 'fr'
              ? 'Prestation de service'
              : 'Service provision',
          count: 17
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['getCurentProjectTypeImmo', 'geteventCalendrier'])
  },

  components: {
    TaskCalendrier,
    SideBarCalender,
    CalendarTify,
    CalendarTifyV2
  },

  methods: {
    hiddenMenuPipeline() {
      this.hiddenContentPipeline = !this.hiddenContentPipeline
    }
  }
}
</script>
<style lang="scss" scoped>
.content-planning {
  position: relative;
  display: flex;
  width: 100%;
  .list-planning {
    cursor: pointer;
    white-space: normal;
    max-height: 100%;
    max-height: calc(100vh - 140px) !important;
    height: calc(100vh - 140px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    width: 390px;
    transform: translateX(0px);
    transition: 0s all ease;
    transition-timing-function: ease-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.menu-hidden {
      width: 27px;
      transform: translateX(0px);
      transition: 0.3s all ease;
      transition-timing-function: ease-out;
    }
    .title-planning {
      //   direction: rtl;
      width: 270px;
      height: 100%;
      padding: 10px 15px 0px 20px;
      white-space: normal;
      max-height: 100%;
      max-height: calc(100vh - 167px) !important;
      height: calc(100vh - 189px) !important;
      overflow-y: auto;
      overflow-x: hidden;
      display: block;
      &.menu-hidden {
        display: none;
      }
      .item-planning {
        margin-bottom: 5px;
        .item {
          font-size: 14px;
          line-height: 15px;
          letter-spacing: -0.41px;
          font-weight: 400;
        }
        .item-count {
          font-size: 12px;
          text-align: center;
          padding: 2px 4px;
          border-radius: 3px;
          position: relative;
          color: white;
          background-color: #704ad1;
        }
      }
      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 19.5px;
        letter-spacing: 1%;
      }
    }
    .transition-pipeline {
      .flech-back-pipeline {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        border-right: 0px !important;
        padding: 25px 0px;
        border: 0px solid #5c2dd3;
        border-radius: 6px 0px 0px 6px;
        background-color: #5c2dd32e;
      }
      .flech-back-pipeline-right {
        border-right: 0px !important;
        padding: 25px 0px;
        border: 0px solid #5c2dd3;
        border-radius: 0px 6px 6px 0px;
        background-color: #5c2dd32e;
      }
      .icon-back {
        transform: rotateY(0deg);
      }
    }
  }
  .block-content-planning {
    overflow-y: auto;
    height: calc(100vh - 20px) !important;
    width: 100%;
    display: block;
    position: relative;
    .header-page-planning {
      margin: 13px 12px 13px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-header {
        font-size: 20px;
        font-weight: 700;
        font-family: 'Montserrat' sans-serif;
        letter-spacing: 1%;
        line-height: 24.38px;
      }
      .choisi-actions {
        border-radius: 3px;
        background-color: transparent;
        padding: 5px 7px 5px 7px;
        border: 1px solid #e5e5e5;
        color: #2f2f2f;
        white-space: nowrap;
        cursor: pointer;
        .label-btn {
          letter-spacing: 1%;
          line-height: 17.07px;
        }
        &.color-gris {
          background-color: #f6f6f6;
        }
        .margin-svg {
          margin-bottom: -3px;
        }
      }
    }
  }

  .Task_Calendrier {
    width: 700px;
    height: calc(100vh - 100px) !important;
  }
}
</style>
