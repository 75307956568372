<template>
  <div class="page-content-Side-Bar">
    <!----------------------------------------------- Mini Calender ----------------------------------------------->

    <v-row justify="center">
      <v-date-picker
        v-model="selectedRange"
        @input="FilterCAlendrier"
        range
        width="230"
        no-title
      ></v-date-picker>
    </v-row>

    <div
      style="margin-top: 20px; text-align:center; font-size:12px; font-weight: 500;"
    >
      {{
        selectedRange[0] && selectedRange[1]
          ? selectedRange[0] + ' - ' + selectedRange[1]
          : selectedRange[0]
      }}
    </div>

    <!------------------------------------------- Filter Type événement ------------------------------------------->
    <v-expansion-panels class="filter-panel mt-7 ">
      <v-expansion-panel>
        <v-expansion-panel-header class="filter-header">
          {{ $i18n.locale === 'fr' ? 'Type événement' : 'Event Type' }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="filter-content">
          <v-container class="px-0" fluid>
            <!-- <v-checkbox
              class="custom-checkbox"
              v-model="selectAllEvents"
              @change="toggleAllEvents"
              label="Select All"
            ></v-checkbox> -->
            <v-checkbox
              class="custom-checkbox"
              v-for="getEvents in getTypeEvent"
              :key="getEvents.id"
              v-model="getEvents.checked"
              :label="getEvents.designation"
              @change="FilterCAlendrier"
            ></v-checkbox>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!---------------------------------------------- Filter Resource ---------------------------------------------->
    <v-expansion-panels class="filter-panel ">
      <v-expansion-panel>
        <v-expansion-panel-header class="filter-header">
          {{ $i18n.locale === 'fr' ? 'Ressource' : 'Resource' }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="filter-content">
          <v-container class="px-0" fluid>
            <!-- <v-checkbox
              class="custom-checkbox"
              v-model="selectAllResources"
              @change="toggleAllResources"
              label="Select All"
            ></v-checkbox> -->
            <v-checkbox
              class="custom-checkbox"
              v-for="resource in getResource"
              :key="resource.id"
              v-model="resource.checked"
              :label="resource.designation"
              @change="FilterCAlendrier"
            >
            </v-checkbox>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!------------------------------------------------ Filter User ------------------------------------------------>
    <v-expansion-panels class="filter-panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="filter-header">
          {{ $i18n.locale === 'fr' ? 'Utilisateur' : 'User' }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="filter-content">
          <v-container class="px-0" fluid>
            <!-- <v-checkbox
              class="custom-checkbox"
              v-model="selectAllUsers"
              @change="toggleAllUsers"
              label="Select All"
            ></v-checkbox> -->
            <v-checkbox
              class="custom-checkbox"
              v-for="user in users"
              :key="user.id"
              v-model="user.checked"
              :label="user.full_name"
              @change="FilterCAlendrier"
            ></v-checkbox>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Axios from '@/axios'
import domain from '@/environment'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      eventTypeCheckbox: true,
      resourceCheckbox: true,
      userCheckbox: true,
      selectAllUsers: false,
      selectAllResources: false,
      selectAllEvents: false,
      users: [],
      selectedRange: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        null
      ]
    }
  },

  methods: {
    ...mapActions([
      'fetchAllResource',
      'fetchAllTypeEvent',
      'fetchAllEventCalender',
      
    ]),
    async fetshUser() {
      const response = await Axios.get(domain + '/api/users', {
        headers: {
          Authorization: `Bearer ${this.currentUser.token}`
        }
      })
      this.users = response.data.data.map(i => {
        return {
          ...i,
          checked: false
        }
      })
    },

    FilterCAlendrier() {
      this.selectedRange = this.selectedRange.sort()
      let params = {}
      let typeE = this.getTypeEvent.filter(i => i.checked == true)
      if (typeE.length > 0) {
        params.type_events = typeE.map(i => i.id)
      }
      let typeR = this.getResource.filter(i => i.checked == true)
      if (typeR.length > 0) {
        params.ressources = typeR.map(i => i.id)
      }
      let User = this.users.filter(i => i.checked == true)
      if (User.length > 0) {
        params.users = User.map(i => i.id)
      }
      if (this.selectedRange[0]) {
        params.start_date = this.selectedRange[0]
      }
      if (this.selectedRange[1]) {
        params.end_date = this.selectedRange[1]
      }
      this.fetchAllEventCalender(params)
    },
    toggleAllResources() {
      const newState = this.selectAllResources
      this.resourcesWithChecked.forEach(resource => {
        resource.checked = newState
      })
      this.FilterCAlendrier()
    },

    toggleAllUsers() {
      // Lorsque la case à cocher "Select All" change d'état, mettez à jour l'état des utilisateurs
      const newState = this.selectAllUsers
      this.usersWithChecked.forEach(user => {
        user.checked = newState
      })
      this.FilterCAlendrier()
    },

    toggleAllEvents() {
      // Lorsque la case à cocher "Select All" change d'état, mettez à jour l'état des événements
      const newState = this.selectAllEvents
      this.eventsWithChecked.forEach(event => {
        event.checked = newState
      })
      this.FilterCAlendrier()
    }
  },

  computed: {
    ...mapGetters(['getResource', 'getTypeEvent', 'currentUser'])
  },

  async mounted() {
    this.FilterCAlendrier()
    this.fetchAllTypeEvent()
    this.fetchAllResource()
    this.fetshUser()
  }
}
</script>
<style lang="scss">
.page-content-Side-Bar {
  .filter-panel.v-item-group.theme--light.v-expansion-panels {
    margin-bottom: 0px;
  }

  .v-input.custom-checkbox.theme--light.v-input--selection-controls.v-input--checkbox {
    padding: 0px;
    margin: 0px;
  }

  .v-expansion-panel-content.filter-content {
    padding: 0px;
  }
  .v-label.theme--light {
    font-size: 11px;
  }
  .v-expansion-panel::before {
    display: none;
  }
  /* Style pour changer le style du titre */
  .v-expansion-panel-header {
    background-color: white;
    color: #333;
    font-weight: bold;
    font-size: 12px;
  }

  /*  Calendrier */
  .custom-datepicker {
    border: 1px solid #ccc;
    border-radius: 8px; /* Modifier la hauteur selon votre besoin */
  }

  /* Styles spécifiques pour masquer l'en-tête */
  .custom-datepicker .v-picker__title {
    display: none;
  }

  .v-date-picker-header {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
  }
  .v-date-picker-header__value button {
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    padding: 0.5rem;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}
</style>
<style scoped lang="scss">
.custom-checkbox::label {
  /* Vos styles personnalisés ici */
  font-weight: bold;
  color: blue;
  /* ... autres styles ... */
}

/* Style for the filter panels */
.filter-panel {
  margin-bottom: 15px;
}

/* Style for the expansion panel headers */
.filter-header {
  padding: 15px;
  cursor: pointer;
}

/* Style for the expansion panel content */
.filter-content {
  padding: 15px;
}

/* Style for the checkboxes */
.custom-checkbox {
}
</style>
